import React from 'react'
import { Link } from 'gatsby'
import '../styles/Navbar.css'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
            navBarActiveClass: 'is-active',
          })
          : this.setState({
            navBarActiveClass: '',
          })
      }
    )
  }

  render() {
    return (
      <nav>
        <Link className="logoArea" to="/">
          <span className="logo">Yuta's Blog</span>
          <span className="kaomoji">o(･_･)Yo!</span>
        </Link>
        <div className="navbar_items">
          <Link className="navbar_item" to="/tags">
            Tags
        </Link>
          <Link className="navbar_item" to="/about">
            About
        </Link>
        </div>
      </nav>
    )
  }
}

export default Navbar
