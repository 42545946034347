import React from 'react'
import { Link } from 'gatsby'
import '../styles/Footer.css'


const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <Link to="/" className="logo footer_logo">
          Yuta's Blog
        </Link>
        <div className="footer_links">
          <a href="https://yuta.run" target="_blank" rel="noopener noreferrer">yuta.run</a>
          <a href="https://twitter.com/hyuta555" target="_blank" rel="noopener noreferrer">Twitter</a>
          <a href="https://github.com/yuta-hayashi" target="_blank" rel="noopener noreferrer">GitHub</a>
        </div>
        <p className="copyRight">&copy; 2020 blog.yuta.run</p>
      </footer>
    )
  }
}

export default Footer
